var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"static-page"},[_c('div',{staticClass:"grid-row"},[_c('div',{staticClass:"col-16"},[_c('h1',[_vm._v(_vm._s(_vm.title))])])]),_c('div',{staticClass:"grid-row"},[_c('div',{staticClass:"col-4 sticky"},[_c('div',{staticClass:"overflow"},[_c('div',_vm._l((_vm.sections),function(section){return _c('div',{key:section.id,staticClass:"menu-item"},[_c('div',{class:{ highlight: section.highlight }},[_c('action',{attrs:{"href":'#' + section.id},on:{"click":function($event){return _vm.clickMenuItem(section)}}},[_vm._v(_vm._s(section.title))])],1),(section.children && section.children.length)?[_c('hr'),_vm._l((section.children),function(child){return _c('div',{key:child.id,class:{ highlight: child.highlight }},[_c('action',{staticClass:"semi-bold",attrs:{"href":'#' + child.id,"icon-pos":"right"},on:{"click":() => {
                      _vm.clickMenuItem(section)
                      if (child.children) {
                        child.showChildren = !child.showChildren
                      }
                    }}},[_vm._v(_vm._s(child.title)),(child.children)?_c('i',{class:[
                      'fas',
                      child.showChildren ? 'fa-caret-down' : 'fa-caret-right',
                    ],attrs:{"slot":"icon"},slot:"icon"}):_vm._e()]),(child.children && child.showChildren)?_c('div',{staticClass:"grandchildren"},_vm._l((child.children),function(grandchild){return _c('div',{key:grandchild.id},[_c('action',{staticClass:"semi-bold",attrs:{"href":'#' + grandchild.id},on:{"click":function($event){return _vm.clickMenuItem(section)}}},[_vm._v(_vm._s(grandchild.title))])],1)}),0):_vm._e()],1)})]:_vm._e()],2)}),0)])]),_c('div',{staticClass:"col-12"},[(_vm.$slots['section-start-slot'])?_c('div',{staticClass:"section-start"},[_vm._t("section-start-slot")],2):_vm._e(),_vm._l((_vm.sections),function(section){return _c('content-section',{key:section.id,staticClass:"has-sections",attrs:{"section":section},on:{"scrollInside":function($event){return _vm.scrollInside(section)}}},[_vm._t("default",null,{"section":section})],2)})],2)]),(_vm.$slots['last-section'])?_c('div',{staticClass:"grid-row"},[_c('div',{staticClass:"col-16"},[_vm._t("last-section")],2)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }