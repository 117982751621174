<script>
import Sticky from '@/helpers/sticky'
import { Action, ContentSection } from 'epmc-patterns/components/v2'

export default {
  components: { Action, ContentSection },
  mixins: [Sticky],
  props: {
    title: {
      type: String,
      default: '',
    },
    sections: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    clickMenuItem(section) {
      section.visible = true
    },
    scrollInside(section) {
      const { sections } = this
      // maybe not performant but ok

      // clear all highlights
      for (let i = 0; i < sections.length; i++) {
        sections[i].highlight = false
        if (sections[i].children) {
          for (let j = 0; j < sections[i].children.length; j++) {
            sections[i].children[j].highlight = false
          }
        }
      }

      const children = section.children
      const hasChildren = children && children.length
      if (hasChildren) {
        for (let i = children.length - 1; i >= 0; i--) {
          const elm = document.getElementById(children[i].id)
          if (elm && elm.getBoundingClientRect().top <= 2) {
            children[i].highlight = true
            break
          }
        }
      }
      if (!hasChildren || !children.some((child) => child.highlight)) {
        section.highlight = true
      }
    },
  },
}
</script>
<template>
  <div class="static-page">
    <div class="grid-row">
      <div class="col-16">
        <h1>{{ title }}</h1>
      </div>
    </div>
    <div class="grid-row">
      <div class="col-4 sticky">
        <div class="overflow">
          <div>
            <div
              v-for="section in sections"
              :key="section.id"
              class="menu-item"
            >
              <div :class="{ highlight: section.highlight }">
                <action
                  :href="'#' + section.id"
                  @click="clickMenuItem(section)"
                  >{{ section.title }}</action
                >
              </div>
              <template v-if="section.children && section.children.length">
                <hr />
                <!-- highlight can be moved inside -->
                <div
                  v-for="child in section.children"
                  :key="child.id"
                  :class="{ highlight: child.highlight }"
                >
                  <action
                    :href="'#' + child.id"
                    class="semi-bold"
                    icon-pos="right"
                    @click="
                      () => {
                        clickMenuItem(section)
                        if (child.children) {
                          child.showChildren = !child.showChildren
                        }
                      }
                    "
                    >{{ child.title
                    }}<i
                      v-if="child.children"
                      slot="icon"
                      :class="[
                        'fas',
                        child.showChildren ? 'fa-caret-down' : 'fa-caret-right',
                      ]"
                  /></action>
                  <div
                    v-if="child.children && child.showChildren"
                    class="grandchildren"
                  >
                    <div
                      v-for="grandchild in child.children"
                      :key="grandchild.id"
                    >
                      <action
                        :href="'#' + grandchild.id"
                        class="semi-bold"
                        @click="clickMenuItem(section)"
                        >{{ grandchild.title }}</action
                      >
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div v-if="$slots['section-start-slot']" class="section-start">
          <slot name="section-start-slot"></slot>
        </div>
        <content-section
          v-for="section in sections"
          :key="section.id"
          :section="section"
          class="has-sections"
          @scrollInside="scrollInside(section)"
        >
          <slot :section="section" />
        </content-section>
      </div>
    </div>
    <div v-if="$slots['last-section']" class="grid-row">
      <div class="col-16">
        <slot name="last-section" />
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.static-page {
  padding-top: $base-unit * 6.5;
  padding-bottom: $base-unit * 13;
  .sticky {
    position: -webkit-sticky;
    position: sticky;
    @media screen and (max-width: $breakpoint-extra-small) {
      position: static;
    }
    margin-top: $base-unit * 8;
    top: $base-unit * 8;
    bottom: 0;
    max-height: 100vh;
    .overflow {
      overflow: auto;
      margin-left: $base-unit * -3;
      max-height: 90vh;
      .menu-item {
        padding: ($base-unit * 1.5) 0;
        > div {
          padding-left: $base-unit * 3;
          &:not(:first-child) {
            margin: ($base-unit * 2) 0;
            line-height: $base-unit * 5;
          }
          &.highlight {
            border-left: $base-unit solid $epmc-light-blue;
            padding-left: $base-unit * 2;
            @media screen and (max-width: $breakpoint-extra-small) {
              margin-left: 0;
              border-left: none;
              padding-left: $base-unit * 3;
            }
            a,
            span {
              color: $epmc-darker-grey;
            }
          }
        }
      }
    }
  }
  .section-start {
    margin-top: $base-unit * 8;
    padding: 0 ($base-unit * 5);
    > * {
      margin-top: 0;
    }
  }
  .grandchildren {
    padding-left: $base-unit * 4;
  }
}
</style>
